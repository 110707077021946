import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Header from "./components/Header";

function App() {
  return (
    <div>
      <Header />

      <main className="container">
        <section>
          <img
            src="https://placehold.co/1400x600?text=MusicMart"
            alt="hero image"
            className="img-fluid"
          />
        </section>

        <section className="py-5">
          <h1 className="text-center py-3">Top Categories</h1>
          <div className="row">
            <div className="col-md-3">
              <div className="card">
                <img
                  src="https://placehold.co/400x300?text=Guitar"
                  alt="guitar-image"
                  className=""
                />
                <Link to="/products/Guitar" className="btn btn-primary mt-3">
                  Guitar
                </Link>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card">
                <img
                  src="https://placehold.co/400x300?text=Piano"
                  alt="piano-image"
                  className=""
                />
                <Link to="/products/Piano" className="btn btn-primary mt-3">
                  Piano
                </Link>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card">
                <img
                  src="https://placehold.co/400x300?text=Ukulele"
                  alt="ukulele-image"
                  className=""
                />
                <Link to="/products/Ukulele" className="btn btn-primary mt-3">
                  Ukulele
                </Link>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card">
                <img
                  src="https://placehold.co/400x300?text=Drum"
                  alt="drum-image"
                  className=""
                />
              </div>
              <Link to="/products/Drum" className="btn btn-primary mt-3 col-12">
                Drum
              </Link>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default App;
