import { useState, useRef, useEffect } from "react";
import Header from "../components/Header";
import "./product.css";
import { useParams } from "react-router-dom";

const products = [
  // Guitars
  {
    id: 1,
    title: "Yamaha F280 40 Inch Acoustic Guitar",
    brand: "Yamaha",
    price: 7000,
    rating: 4.3,
    category: "Guitar",
    imageUrl: "https://m.media-amazon.com/images/I/71RkY055j7L._SX679_.jpg",
  },
  {
    id: 2,
    title: "Fender Squier SA-150 Acoustic Guitar",
    brand: "Fender",
    price: 8500,
    rating: 4.6,
    category: "Guitar",
    imageUrl: "https://m.media-amazon.com/images/I/41sshNRc82L._SX679_.jpg",
  },
  {
    id: 3,
    title: "Ibanez PF15-BK Acoustic Guitar",
    brand: "Ibanez",
    price: 9500,
    rating: 4.5,
    category: "Guitar",
    imageUrl: "https://m.media-amazon.com/images/I/51t7Har-d2L._SX679_.jpg",
  },
  {
    id: 4,
    title: "Cort AD810 Dreadnought Acoustic Guitar",
    brand: "Cort",
    price: 8000,
    rating: 4.4,
    category: "Guitar",
    imageUrl: "https://m.media-amazon.com/images/I/51aifC4It0L._SX679_.jpg",
  },
  {
    id: 5,
    title: "Epiphone PRO-1 Acoustic Guitar",
    brand: "Epiphone",
    price: 7200,
    rating: 4.2,
    category: "Guitar",
    imageUrl:
      "https://guitarworks.ca/cdn/shop/products/rukfewmwyb8qhfslzhmg_2000x.jpg?v=1569029042",
  },
  // Ukuleles
  {
    id: 6,
    title: "Kala KA-15S Mahogany Soprano Ukulele",
    brand: "Kala",
    price: 3200,
    rating: 3.7,
    category: "Ukulele",
    imageUrl:
      "https://www.bajaao.com/cdn/shop/files/kala-soprano-ukuleles-kala-ka-15s-mahogany-soprano-ukulele-open-box-4493977550920.jpg?v=1686218622&width=1500",
  },
  {
    id: 7,
    title: "Fender Grace VanderWaal Signature Ukulele",
    brand: "Fender",
    price: 6500,
    rating: 2.8,
    category: "Ukulele",
    imageUrl:
      "https://easymusiccenter.com/cdn/shop/products/vander_1556x.png?v=1621495766",
  },
  {
    id: 8,
    title: "Lanikai LU-21 Soprano Ukulele",
    brand: "Lanikai",
    price: 4500,
    rating: 4.5,
    category: "Ukulele",
    imageUrl:
      "https://bhatiamusicals.in/wp-content/uploads/2020/01/Lanikai-LU21-Soprano.jpg",
  },
  {
    id: 9,
    title: "Cordoba 15CM Concert Ukulele",
    brand: "Cordoba",
    price: 5500,
    rating: 1.6,
    category: "Ukulele",
    imageUrl:
      "https://images-eu.ssl-images-amazon.com/images/I/61ItwYOVXTL._AC_UL210_SR210,210_.jpg",
  },
  {
    id: 10,
    title: "Donner DUC-200 Mahogany Concert Ukulele",
    brand: "Donner",
    price: 4000,
    rating: 1.4,
    category: "Ukulele",
    imageUrl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIBtMsPlFQwhIeXtbQYSA5MWaK841Nw7AA_A&s",
  },
  // Drums
  {
    id: 11,
    title: "Yamaha Rydeen 5-Piece Drum Set with Cymbals",
    brand: "Yamaha",
    price: 35000,
    rating: 4.7,
    category: "Drum",
    imageUrl:
      "https://rajmusical.com/ekart/images/7349Yamaha-RYDEEN-RDP2F5-BG-Acoustic-Drum-Set-With-Hardware---Burgandy-Glitter_3.jpg",
  },
  {
    id: 12,
    title: "Pearl Export EXX 5-Piece Drum Set with Hardware",
    brand: "Pearl",
    price: 42000,
    rating: 3.8,
    category: "Drum",
    imageUrl:
      "https://furtadosonline.gumlet.io/media/catalog/product/2/1/214007_1.jpg",
  },
  {
    id: 13,
    title: "Tama Imperialstar 6-Piece Drum Set with Meinl Cymbals",
    brand: "Tama",
    price: 47000,
    rating: 4.9,
    category: "Drum",
    imageUrl:
      "https://media.sweetwater.com/m/products/image/2364073a16ypuvCp3LSQifrkjYXAcimAKRobFDvy.jpg?quality=82&width=750&ha=2364073a16eb1326",
  },
  {
    id: 14,
    title: "Ludwig Accent Drive 5-Piece Drum Set",
    brand: "Ludwig",
    price: 39000,
    rating: 2.6,
    category: "Drum",
    imageUrl: "https://m.media-amazon.com/images/I/712I6mIUhbL.jpg",
  },
  {
    id: 15,
    title: "Mapex Tornado 5-Piece Drum Kit",
    brand: "Mapex",
    price: 32000,
    rating: 1.5,
    category: "Drum",
    imageUrl:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZFTi0YMzzc8KDu0OrZL4i1wwrafGfHFXZKw&s",
  },
  // Pianos
  {
    id: 16,
    title: "Yamaha P-45 Digital Piano with 88 Weighted Keys",
    brand: "Yamaha",
    price: 45000,
    rating: 4.9,
    category: "Piano",
    imageUrl:
      "https://www.bajaao.com/cdn/shop/files/yamaha-digital-pianos-yamaha-p45-88-key-digital-piano-33670133350579.jpg?v=1697177926&width=1000",
  },
  {
    id: 17,
    title: "Casio Privia PX-160 Digital Piano",
    brand: "Casio",
    price: 42000,
    rating: 4.8,
    category: "Piano",
    imageUrl:
      "https://www.bajaao.com/cdn/shop/files/casio-digital-pianos-casio-px-160-privia-88-key-digital-piano-with-casio-cs-67p-piano-stand-3579056422984.jpg?v=1690080164",
  },
  {
    id: 18,
    title: "Roland FP-30X Portable Digital Piano",
    brand: "Roland",
    price: 47000,
    rating: 4.9,
    category: "Piano",
    imageUrl:
      "https://images-eu.ssl-images-amazon.com/images/I/61JNX7kEwrS._AC_UL600_SR600,600_.jpg",
  },
  {
    id: 19,
    title: "Korg B2SP Digital Piano with Stand",
    brand: "Korg",
    price: 43000,
    rating: 4.7,
    category: "Piano",
    imageUrl:
      "https://www.musicdelight.com.sg/images/products/11356842991571907649.jpg",
  },
  {
    id: 20,
    title: "Alesis Recital Pro 88-Key Digital Piano",
    brand: "Alesis",
    price: 40000,
    rating: 3.6,
    category: "Piano",
    imageUrl: "https://m.media-amazon.com/images/I/71x9W5OP01L.jpg",
  },
];

const Products = () => {
  const [price, setPrice] = useState(0);
  const sliderValue = useRef(0);
  const [renderSliderPrice, setRenderSliderPrice] = useState(0);

  const [categoryInput, setCategoryInput] = useState([]);
  const [ratingInput, setRatingInput] = useState(0);
  const [priceSorting, setPriceSorting] = useState("");
  const [renderData, setRenderData] = useState(products);

  const { categoryParams } = useParams();

  useEffect(() => {
    if (categoryParams) {
      const selectedCategoryData = products.filter(
        (product) => product.category === categoryParams
      );
      setRenderData(selectedCategoryData);
      // setCategoryInput(categoryParams);
    }
  }, [categoryParams]);

  const priceSliderHandler = (event) => {
    sliderValue.current = event.target.value;
    setRenderSliderPrice(event.target.value);
  };

  const priceBtnHandler = () => {
    const selectedPrice = sliderValue.current;
    const filteredPriceItems = products.filter((product) =>
      selectedPrice >= 25000
        ? product.price >= selectedPrice && product.price <= 50000
        : product.price >= 3000 && product.price <= selectedPrice
    );

    setRenderData(filteredPriceItems);
    setPrice(selectedPrice);
  };

  const categoryHandler = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      const updatedCategory = [...categoryInput, value];
      showCategoryProducts(updatedCategory);
    } else {
      const updatedCategory = categoryInput.filter((item) => item !== value);
      showCategoryProducts(updatedCategory);
    }
  };

  const showCategoryProducts = (updatedData) => {
    if (
      updatedData.length < 1 &&
      ratingInput === 0 &&
      !priceSorting &&
      price === 0
    ) {
      //when none of the inputs are selected
      setRenderData(products);
    }

    if (ratingInput !== 0 || priceSorting !== "" || price !== 0) {
      if (ratingInput && priceSorting && price) {
        const filteredProducts = updatedData.map((item) =>
          products.filter(
            (product) =>
              product.category === item &&
              product.rating >= ratingInput &&
              (price >= 25000
                ? product.price >= price && product.price <= 50000
                : product.price >= 3000 && product.price <= price)
          )
        );
        const concatenatedArray = []
          .concat(...filteredProducts)
          .toSorted((a, b) =>
            priceSorting === "low to high"
              ? a.price - b.price
              : b.price - a.price
          );

        setRenderData(concatenatedArray);
      } else if (ratingInput && priceSorting) {
        if (updatedData.length < 1) {
          const filteredItems = products
            .filter((product) => product.rating >= ratingInput)
            .toSorted((a, b) =>
              priceSorting === "low to high"
                ? a.price - b.price
                : b.price - a.price
            );
          setRenderData(filteredItems);
        } else {
          const ratingFilteredItems = updatedData.map((item) =>
            products.filter(
              (product) =>
                product.rating >= ratingInput && product.category === item
            )
          );

          const concatenatedArray = []
            .concat(...ratingFilteredItems)
            .toSorted((a, b) =>
              priceSorting === "low to high"
                ? a.price - b.price
                : b.price - a.price
            );

          setRenderData(concatenatedArray);
        }
      } else if (ratingInput && price) {
        const filteredItems = updatedData.map((item) =>
          products.filter(
            (product) =>
              product.category === item &&
              product.rating >= ratingInput &&
              (price >= 25000
                ? product.price >= price && product.price <= 50000
                : product.price >= 3000 && product.price <= price)
          )
        );
        const concatenatedArray = [].concat(...filteredItems);
        setRenderData(concatenatedArray);
      } else if (priceSorting && price) {
        const filteredItems = updatedData.map((item) =>
          products.filter(
            (product) =>
              product.category === item &&
              (price >= 25000
                ? product.price >= price && product.price <= 50000
                : product.price >= 3000 && product.price <= price)
          )
        );
        const concatenatedArray = [].concat(...filteredItems);
        const filteredItemsWithSorting = concatenatedArray.toSorted((a, b) =>
          priceSorting === "low to high" ? a.price - b.price : b.price - a.price
        );
        setRenderData(filteredItemsWithSorting);
      } else if (price) {
        const filteredItems = updatedData.map((item) =>
          products.filter(
            (product) =>
              product.category === item &&
              (price >= 25000
                ? product.price >= price && product.price <= 50000
                : product.price >= 3000 && product.price <= price)
          )
        );
        const concatenatedArray = [].concat(...filteredItems);
        setRenderData(concatenatedArray);
      } else if (ratingInput) {
        const filteredItems = updatedData.map((item) =>
          products.filter(
            (product) =>
              product.category === item && product.rating >= ratingInput
          )
        );
        const concatenatedArray = [].concat(...filteredItems);
        setRenderData(concatenatedArray);
      } else {
        // console.log("I'm working");

        //for sorted data
        const sortedItems = updatedData.map((item) =>
          products.filter((product) => product.category === item)
        );
        console.log(sortedItems);
        const concatenatedArray = []
          .concat(...sortedItems)
          .toSorted((a, b) =>
            priceSorting === "low to high"
              ? a.price - b.price
              : b.price - a.price
          );
        console.log(concatenatedArray);
        setRenderData(concatenatedArray);
      }
    } else {
      if (updatedData.length < 1) {
        setRenderData(products);
      } else {
        const filteredProducts = updatedData.map((category) =>
          products.filter((product) => product.category === category)
        );

        const concatenatedArray = [].concat(...filteredProducts);
        setRenderData(concatenatedArray);
      }
    }

    setCategoryInput(updatedData);
  };

  const ratingHandler = (event) => {
    const ratingValue = parseInt(event.target.value);

    if (categoryInput.length !== 0 || priceSorting !== "" || price !== 0) {
      if (categoryInput.length > 0 && priceSorting && price > 0) {
        const filteredProducts = categoryInput.map((item) =>
          products.filter(
            (product) =>
              product.category === item &&
              product.rating >= ratingValue &&
              (price >= 25000
                ? product.price >= price && product.price <= 50000
                : product.price >= 3000 && product.price <= price)
          )
        );
        const concatenatedArray = []
          .concat(...filteredProducts)
          .toSorted((a, b) =>
            priceSorting === "low to high"
              ? a.price - b.price
              : b.price - a.price
          );

        setRenderData(concatenatedArray);
      } else if (categoryInput.length > 0 && priceSorting) {
        const filteredProducts = categoryInput.map((item) =>
          products.filter(
            (product) =>
              product.rating >= ratingValue && product.category === item
          )
        );
        const concatenatedArray = [].concat(...filteredProducts);
        const sortedCategoryAndRating = concatenatedArray.toSorted((a, b) =>
          priceSorting === "low to high" ? a.price - b.price : b.price - a.price
        );

        setRenderData(sortedCategoryAndRating);
      } else if (priceSorting) {
        const filteredRatingProducts = products.filter(
          (product) => product.rating >= ratingValue
        );
        const sortedRatingProducts = filteredRatingProducts.toSorted((a, b) =>
          priceSorting === "low to high" ? a.price - b.price : b.price - a.price
        );

        setRenderData(sortedRatingProducts);
      } else {
        const filteredProducts = categoryInput.map((item) =>
          products.filter(
            (product) =>
              product.rating >= ratingValue && product.category === item
          )
        );
        const concatenatedArray = [].concat(...filteredProducts);

        setRenderData(concatenatedArray);
      }
    } else {
      const filteredRatingProducts = products.filter(
        (product) => product.rating >= ratingValue
      );
      setRenderData(filteredRatingProducts);
    }

    setRatingInput(ratingValue);
  };

  const priceSortHandler = (event) => {
    const sortValue = event.target.value;

    if (categoryInput.length !== 0 || ratingInput !== 0) {
      // if (categoryInput.length !== 0 && ratingInput !== 0) {
      //     const ratingCategoryItems = [...renderData]
      //     const sortedItemsWithRatingCategory = ratingCategoryItems.toSorted((a, b) => sortValue === "low to high" ? a.price - b.price : b.price - a.price)

      //     setRenderData(sortedItemsWithRatingCategory)
      // } else {
      //     const sortedItems = renderData.toSorted((a, b) => sortValue === "low to high" ? a.price - b.price : b.price - a.price)

      //     setRenderData(sortedItems)
      // }
      const sortedItems = renderData.toSorted((a, b) =>
        sortValue === "low to high" ? a.price - b.price : b.price - a.price
      );

      setRenderData(sortedItems);
    } else {
      if (sortValue === "low to high") {
        const lowToHighPriceData = products.toSorted(
          (a, b) => a.price - b.price
        );
        setRenderData(lowToHighPriceData);
      } else {
        const highToLowPriceData = products.toSorted(
          (a, b) => b.price - a.price
        );
        setRenderData(highToLowPriceData);
      }
    }

    setPriceSorting(sortValue);
  };

  const resetFilterForm = () => {
    setCategoryInput([]);
    setRatingInput(0);
    setPriceSorting("");
    setPrice(0);
    sliderValue.current = 0;
    setRenderSliderPrice(0);
    setRenderData(products);
  };

  return (
    <>
      <Header />
      <main className="container py-5">
        <div className="row">
          <div id="filterSection" className="col-md-2">
            <form id="filterForm">
              <div>
                <span className="fw-bold fs-5">Filters</span>
                <button
                  type="button"
                  className="float-end btn btn-outline-danger rounded-pill"
                  onClick={resetFilterForm}
                >
                  Clear
                </button>
              </div>

              <div className="py-3">
                <div>
                  <label htmlFor="priceInput">
                    <span className="fw-bold fs-5">Price</span>
                  </label>
                  <br />
                  <div
                    className="d-flex justify-content-between mt-1"
                    style={{ width: "70%" }}
                  >
                    <span className="priceLabel">3000</span>
                    <span className="priceLabel">25000</span>
                    <span className="priceLabel">50000</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <input
                      type="range"
                      id="priceInput"
                      min={3000}
                      max={50000}
                      onChange={priceSliderHandler}
                      defaultValue={20000}
                      style={{ width: "70%" }}
                    />
                    <button
                      type="button"
                      className="btn btn-outline-primary rounded-pill"
                      onClick={priceBtnHandler}
                    >
                      Go
                    </button>
                  </div>
                  {renderSliderPrice !== 0 && (
                    <span>
                      Price range:{" "}
                      {renderSliderPrice <= 25000
                        ? `₹ 3000 - ₹ ${renderSliderPrice}`
                        : `₹ ${renderSliderPrice} - ₹ 50000`}
                    </span>
                  )}
                </div>
              </div>

              <div className="py-3">
                <span className="fw-bold">Category</span>
                <br />
                <label htmlFor="guitar">
                  <input
                    type="checkbox"
                    id="guitar"
                    name="categories"
                    value="Guitar"
                    onChange={categoryHandler}
                    checked={categoryInput.includes("Guitar")}
                  />
                  Guitar
                </label>
                <br />
                <label htmlFor="piano">
                  <input
                    type="checkbox"
                    id="piano"
                    name="categories"
                    value="Piano"
                    onChange={categoryHandler}
                    checked={categoryInput.includes("Piano")}
                  />
                  Piano
                </label>
                <br />
                <label htmlFor="ukulele">
                  <input
                    type="checkbox"
                    id="ukulele"
                    name="categories"
                    value="Ukulele"
                    onChange={categoryHandler}
                    checked={categoryInput.includes("Ukulele")}
                  />
                  Ukulele
                </label>
                <br />
                <label htmlFor="drum">
                  <input
                    type="checkbox"
                    id="drum"
                    name="categories"
                    value="Drum"
                    onChange={categoryHandler}
                    checked={categoryInput.includes("Drum")}
                  />
                  Drum
                </label>
              </div>

              <div className="py-3">
                <span className="fw-bold">Rating</span>
                <br />
                <label htmlFor="fourStarRate">
                  <input
                    type="radio"
                    name="ratings"
                    id="fourStarRate"
                    value={4}
                    onChange={ratingHandler}
                    checked={ratingInput === 4}
                  />
                  4 stars & above
                </label>
                <br />
                <label htmlFor="threeStarRate">
                  <input
                    type="radio"
                    name="ratings"
                    id="threeStarRate"
                    value={3}
                    onChange={ratingHandler}
                    checked={ratingInput === 3}
                  />
                  3 stars & above
                </label>
                <br />
                <label htmlFor="twoStarRate">
                  <input
                    type="radio"
                    name="ratings"
                    id="twoStarRate"
                    value={2}
                    onChange={ratingHandler}
                    checked={ratingInput === 2}
                  />
                  2 stars & above
                </label>
                <br />
                <label htmlFor="oneStarRate">
                  <input
                    type="radio"
                    name="ratings"
                    id="oneStarRate"
                    value={1}
                    onChange={ratingHandler}
                    checked={ratingInput === 1}
                  />
                  1 stars & above
                </label>
              </div>

              <div className="py-3">
                <span className="fw-bold">Sort by</span>
                <br />
                <label htmlFor="lowToHigh">
                  <input
                    type="radio"
                    id="lowToHigh"
                    name="priceSorting"
                    value="low to high"
                    onChange={priceSortHandler}
                    checked={priceSorting === "low to high"}
                  />
                  Price - Low to High
                </label>
                <br />
                <label htmlFor="HighToLow">
                  <input
                    type="radio"
                    id="HighToLow"
                    name="priceSorting"
                    value="high to low"
                    onChange={priceSortHandler}
                    checked={priceSorting === "high to low"}
                  />
                  Price - High to Low
                </label>
              </div>
            </form>
          </div>

          <div className="col-md-10">
            <h1 className="text-center">Products List</h1>
            <div>
              Showing results for{" "}
              <span className="fw-bold">{renderData.length}</span> items.
            </div>
            <div className="row mt-3">
              {renderData.map((item) => (
                // <>
                <div key={item.id} className="col-md-3 mb-4">
                  <div className="card">
                    <img src={item.imageUrl} className="img-fluid" />
                    <div className="card-body">
                      <h6>{item.title}</h6>
                      {/* <h6>{item.price}</h6> */}
                      <div>
                        <span className="fw-bold fs-4">
                          &#8377;{item.price}
                        </span>
                        <span className="float-end">rating: {item.rating}</span>
                      </div>
                      <div className="d-grid gap-2 mt-3">
                        <button className="btn btn-warning" type="button">
                          Add to cart
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                // </>
              ))}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Products;
