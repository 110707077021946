import './header.css'
import { NavLink } from "react-router-dom"
import { Link } from "react-router-dom"

const Header = () => {
    return (
        <header className="py-2">
            <div className="container">
                <nav className="navbar navbar-expand-lg">
                    <NavLink className="navbar-brand" to="/">MusicMart</NavLink>
                    {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button> */}
                    {/* <div className="collapse navbar-collapse" id="navbarNavAltMarkup"> */}
                    <div className="navbar-nav mx-auto">
                        <Link className="nav-link" to="/">Home</Link>
                        <Link className="nav-link" to="/products">Products</Link>
                        <Link className="nav-link" to="/about">About</Link>
                    </div>
                    {/* </div> */}

                    <form className='me-5'>
                        <input id="searchInput" className="form-control" type="search" placeholder="Search Products..."></input>
                    </form>

                    <div className='ms-5'>
                        <button className="bg-dark border fs-5 border-dark bg-opacity-50 text-light me-5 px-3">Login</button>

                        <img src="https://i.postimg.cc/6QZg7m6g/add-fav.png" alt="add to favourite" className="headerIconImg me-3" />

                        <img src="https://i.postimg.cc/Bb7Qjq2c/add-to-cart.png" alt='add to cart' className='headerIconImg' />Cart
                    </div>
                </nav>

            </div>
        </header>
    )
}

export default Header